import { useState } from "react";
import { Entry, Result } from "./interfaces";
import { saveQuizResults } from "./services";
import { HttpStatus } from "../shared/constants";
import { Loading } from "../shared/Loading";
import { formatDecimal } from "../shared/utils";

export function Question({
  questions,
  setQuizActive,
}: {
  questions: Entry[];
  setQuizActive: (is: boolean) => void;
}): JSX.Element {
  const [status, setStatus] = useState(HttpStatus.IDLE);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [results, setResults] = useState([] as Result[]);
  const [showHint, setShowHint] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [showResults, setShowResults] = useState(false);

  function submitAnswer(option: string): void {
    const id = questions[currentQuestion].id;
    const type = questions[currentQuestion].type;
    const question = questions[currentQuestion].question;
    const hint = questions[currentQuestion].hint;
    const solution = questions[currentQuestion].solution;
    if (option === solution) {
      setShowSuccess(true);
      setScore(score + 1);
      setResults((r) => [
        ...r,
        {
          id: id,
          type: type,
          question: question,
          solution: solution,
          hint: hint,
          correct: true,
        },
      ]);
    } else {
      setShowFailure(true);
      setResults((r) => [
        ...r,
        {
          id: id,
          type: type,
          question: question,
          solution: solution,
          hint: hint,
          correct: false,
        },
      ]);
    }
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else if (currentQuestion + 1 === questions.length) {
      setCurrentQuestion(currentQuestion + 1);
      setShowResults(true);
    }
    setShowHint(false);
  }

  async function endQuiz() {
    setStatus(HttpStatus.WORKING);
    try {
      await saveQuizResults(results);
    } catch (error) {
      setStatus(HttpStatus.ERROR);
      console.error((error as Error).cause);
    } finally {
      setScore(0);
      setCurrentQuestion(0);
      setShowResults(false);
      setQuizActive(false);
    }
  }

  return (
    <>
      {questions.length === 0 ? (
        <></>
      ) : showResults && !showSuccess && !showFailure ? (
        <div className="quiz-results center">
          <p className="header title">Quiz results</p>
          <div className="score-group">
            <p className="emphasis header score">
              {formatDecimal(score / questions.length)}
            </p>
            <p>
              {score} out of {questions.length} correct
            </p>
          </div>
          {results.some((r) => r.correct === false) && (
            <div>
              <p className="error">Incorrect answers</p>
              {results
                .filter((r) => r.correct === false)
                .map((r, i) => (
                  <div className="answer" key={i}>
                    <p className="emphasis">{r.question}</p>
                    {r.hint && (
                      <p className="hint">({r.hint.toLowerCase()}) </p>
                    )}
                    <p>is "{r.solution}"</p>
                  </div>
                ))}
            </div>
          )}
          <div className="button-group">
            {status === HttpStatus.WORKING ? (
              <Loading />
            ) : (
              <button onClick={() => endQuiz()}>Save results</button>
            )}
          </div>
        </div>
      ) : showSuccess ? (
        <div className="center">
          <p className="success">Correct</p>
          <div className="answer">
            <p className="emphasis">
              {questions[currentQuestion - 1].question}
            </p>
            <p> is "{questions[currentQuestion - 1].solution}"</p>
          </div>
          <div className="button-group">
            <button onClick={() => setShowSuccess(false)}>Ok</button>
          </div>
        </div>
      ) : showFailure ? (
        <div className="center">
          <p className="error">Incorrect</p>
          <div className="answer">
            <p className="emphasis">
              {questions[currentQuestion - 1].question}
            </p>
            <p> is "{questions[currentQuestion - 1].solution}"</p>
          </div>
          <div className="button-group">
            <button onClick={() => setShowFailure(false)}>Ok</button>
          </div>
        </div>
      ) : (
        <>
          <div className="question-card">
            <p>
              Question {currentQuestion + 1} out of {questions.length}
            </p>
            <p className="question">{questions[currentQuestion]?.question}</p>
            <p className="hint center">
              {showHint && questions[currentQuestion].hint.toLowerCase()}
            </p>
            <div className="button-group stats-group">
              {questions[currentQuestion].options.map((option) => (
                <button onClick={() => submitAnswer(option)}>{option}</button>
              ))}
            </div>
          </div>
          {questions[currentQuestion].hint && (
            <div className="bottom-bar">
              <div></div>
              <div className="button-margin">
                <button
                  className="add icon"
                  disabled={showHint}
                  onClick={() => setShowHint(true)}
                >
                  <i className="material-icons">question_mark</i>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
