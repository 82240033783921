import { useEffect, useRef, useState } from "react";
import ReactSlider from "react-slider";
import { Entry, QuizParams } from "./interfaces";
import { generateQuiz } from "./services";
import { Loading } from "../shared/Loading";
import { HttpStatus } from "../shared/constants";
import { Error } from "../shared/Error";
import { NavBar } from "../shared/NavBar";
import { Question } from "./Question";

const defaultQuizLength = 20;
const maxQuizLength = 50;
const minQuizLength = 10;
const quizLengthInterval = 10;

export function Quiz(): JSX.Element {
  const [errors, setErrors] = useState({
    http: "",
  });
  const [status, setStatus] = useState(HttpStatus.IDLE);
  const [quizParams, setQuizParams] = useState({
    length: defaultQuizLength,
    scope: {
      hiragana: false,
      katakana: false,
      vocabulary: false,
    },
  } as QuizParams);
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [questions, setQuestions] = useState([] as Entry[]);
  const [quizActive, setQuizActive] = useState(false);
  const hiraganaCheckbox = useRef<HTMLInputElement>(null!);
  const katakanaCheckbox = useRef<HTMLInputElement>(null!);
  const vocabularyCheckbox = useRef<HTMLInputElement>(null!);
  const missingFields = Object.values(quizParams.scope).every(
    (p) => p === false,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {}, [questions]);
  useEffect(() => {
    if (missingFields) {
      setbuttonDisabled(true);
    } else {
      setbuttonDisabled(false);
    }
  }, [missingFields]);

  function updateScope(
    checkbox: React.MutableRefObject<HTMLInputElement>,
    category: string,
  ) {
    if (checkbox.current.checked === true) {
      setQuizParams((p) => ({
        ...p,
        scope: { ...p.scope, [category]: true },
      }));
    } else {
      setQuizParams((p) => ({
        ...p,
        scope: { ...p.scope, [category]: false },
      }));
    }
  }

  function updateLength(value: any) {
    setQuizParams((p) => ({
      ...p,
      length: value,
    }));
  }

  async function handleGenerate(): Promise<void> {
    setStatus(HttpStatus.WORKING);
    try {
      const data = await generateQuiz(quizParams);
      setQuestions(data);
      setQuizActive(true);
      setQuizParams({
        length: 20,
        scope: {
          hiragana: false,
          katakana: false,
          vocabulary: false,
        },
      });
      setStatus(HttpStatus.SUCCESS);
    } catch (error) {
      setErrors((errors) => ({ ...errors, http: (error as Error).message }));
      setStatus(HttpStatus.ERROR);
      console.error((error as Error).cause);
    }
  }

  return (
    <>
      {!quizActive && (
        <>
          <div className="header">
            <h1>クイズ</h1>
          </div>
          <div className="center">
            <div className="option-group">
              <div className="option-item">
                <p className="option-label">Scope</p>
                <label className="block">
                  <input
                    className="checkbox"
                    type="checkbox"
                    ref={hiraganaCheckbox}
                    onClick={() => updateScope(hiraganaCheckbox, "hiragana")}
                  />
                  Hiragana
                </label>
                <label className="block">
                  <input
                    className="checkbox"
                    type="checkbox"
                    ref={katakanaCheckbox}
                    onClick={() => updateScope(katakanaCheckbox, "katakana")}
                  />
                  Katakana
                </label>
                <label className="block">
                  <input
                    className="checkbox"
                    type="checkbox"
                    ref={vocabularyCheckbox}
                    onClick={() =>
                      updateScope(vocabularyCheckbox, "vocabulary")
                    }
                  />
                  Vocabulary
                </label>
              </div>
              <div className="option-item">
                <p className="option-label">Number of questions</p>
                <ReactSlider
                  className="slider"
                  defaultValue={defaultQuizLength}
                  max={maxQuizLength}
                  min={minQuizLength}
                  onChange={(value: any) => updateLength(value)}
                  renderThumb={(props: any, state: any) => (
                    <div {...props}>{state.valueNow}</div>
                  )}
                  step={quizLengthInterval}
                  thumbClassName="slider-selector"
                />
              </div>
            </div>
            {status === HttpStatus.ERROR && <Error message={errors.http} />}
            <div className="button-group">
              {status === HttpStatus.WORKING ? (
                <Loading />
              ) : (
                <button disabled={buttonDisabled} onClick={handleGenerate}>
                  Start quiz
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {quizActive && (
        <Question questions={questions} setQuizActive={setQuizActive} />
      )}
      <NavBar />
    </>
  );
}
