import { Entry, QuizParams, Result, StatsData } from "./interfaces";
import { Response } from "../shared/interfaces";
import { retryFetch, handleError, ExpectedError } from "../shared/utils";

export async function generateQuiz(params: QuizParams): Promise<Entry[]> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/generate-quiz`, {
        body: JSON.stringify(params),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }),
    );
    const { status, data, message }: Response = await response?.json();
    if (status === "success") {
      return data as Entry[];
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
    return data as Entry[];
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function saveQuizResults(results: Result[]): Promise<void> {
  const payload = results.map((r) => ({
    id: r.id,
    type: r.type,
    correct: r.correct,
  }));
  try {
    const response = await retryFetch(() =>
      fetch(
        `${process.env.REACT_APP_BACKEND_URL as string}/save-quiz-results`,
        {
          body: JSON.stringify(payload),
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        },
      ),
    );
    const { status, message }: Response = await response?.json();
    if (status === "success") {
      return;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function getQuizStats(): Promise<StatsData> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/get-quiz-stats`, {
        credentials: "include",
      }),
    );
    const { status, data, message }: Response = await response?.json();
    if (status === "success") {
      return data as StatsData;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
    return data as StatsData;
  } catch (error) {
    throw handleError(error as Error);
  }
}
