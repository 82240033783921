import { FormEvent, useState } from "react";
import isEqual from "lodash.isequal";
import { SwipeToDelete } from "../shared/SlideDelete";
import { editTerm, deleteTerm } from "./services";
import { Term } from "./interfaces";

export function TermCard({ term }: { term: Term }): JSX.Element {
  const [updatedTerm, setUpdatedTerm] = useState(term);

  function updateTerm(
    event: FormEvent<HTMLParagraphElement>,
    field: keyof Term,
  ): void {
    setUpdatedTerm((updatedTerm) => ({
      ...updatedTerm,
      [field]: event.currentTarget?.textContent?.trim() ?? term[field].trim(),
    }));
    handleUpdate({
      ...updatedTerm,
      [field]: event.currentTarget?.textContent?.trim() ?? term[field].trim(),
    });
  }

  async function handleUpdate(termToUpdate: Term): Promise<void> {
    if (isEqual(termToUpdate, term)) {
      return;
    }
    try {
      await editTerm(termToUpdate);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDelete(termID: string): Promise<void> {
    try {
      await deleteTerm(termID);
    } catch (error) {
      console.error((error as Error).cause);
    }
  }

  return (
    <SwipeToDelete onDelete={() => handleDelete(term.id)}>
      <div className="term-card multiline">
        <div>
          <p
            className="not-empty"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onBlur={(event) => updateTerm(event, "native")}
          >
            {updatedTerm.native.trim()}
          </p>
        </div>
        <div>
          <p
            className="right emphasis not-empty"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onBlur={(event) => updateTerm(event, "japanese")}
          >
            {updatedTerm.japanese.trim()}
          </p>
          <p
            className="right hint not-empty"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onBlur={(event) => updateTerm(event, "romaji")}
          >
            {updatedTerm.romaji.toLowerCase().trim()}
          </p>
        </div>
      </div>
    </SwipeToDelete>
  );
}
