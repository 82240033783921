import { defaultErrorMessage, networkErrorMessage } from "./Error";

export class ExpectedError extends Error {}

export async function retryFetch(call: () => Promise<Response>, retries = 3) {
  let response: Response | undefined;
  let retry = 0;

  while (retry <= retries) {
    const res = await call();
    if (!res.ok) {
      if (res.status >= 500 && retry < retries) {
        retry++;
        // eslint-disable-next-line no-loop-func
        await new Promise((f) => setTimeout(f, (retry / 2) * 1000));
        continue;
      }
      if (retry === retries) {
        throw res;
      }
    }
    response = res;
    break;
  }

  return response;
}

export function handleError(error: Error) {
  let message = defaultErrorMessage;

  if (error instanceof ExpectedError) {
    if (error.message) {
      message = error.message;
    }
  } else {
    if (error?.message?.includes("NetworkError")) {
      message = networkErrorMessage;
    }
  }

  return Error(message, {
    cause: error.message || message,
  });
}

export function transformDate(date: Date): string {
  const d = new Date(date);

  return d.toLocaleDateString("en-EN", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}

export function formatDecimal(float: number): string {
  return (float * 100).toFixed(2) + "%";
}
