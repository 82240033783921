export const characters = [
  {
    hiragana: "あ",
    katakana: "ア",
    romaji: "a",
  },
  {
    hiragana: "い",
    katakana: "イ",
    romaji: "i",
  },
  {
    hiragana: "う",
    katakana: "ウ",
    romaji: "u",
  },
  {
    hiragana: "え",
    katakana: "エ",
    romaji: "e",
  },
  {
    hiragana: "お",
    katakana: "オ",
    romaji: "o",
  },
  {
    hiragana: "か",
    katakana: "カ",
    romaji: "ka",
  },
  {
    hiragana: "き",
    katakana: "キ",
    romaji: "ki",
  },
  {
    hiragana: "く",
    katakana: "ク",
    romaji: "ku",
  },
  {
    hiragana: "け",
    katakana: "ケ",
    romaji: "ke",
  },
  {
    hiragana: "こ",
    katakana: "コ",
    romaji: "ko",
  },
  {
    hiragana: "さ",
    katakana: "サ",
    romaji: "sa",
  },
  {
    hiragana: "し",
    katakana: "シ",
    romaji: "shi",
  },
  {
    hiragana: "す",
    katakana: "ス",
    romaji: "su",
  },
  {
    hiragana: "せ",
    katakana: "セ",
    romaji: "se",
  },
  {
    hiragana: "そ",
    katakana: "ソ",
    romaji: "so",
  },
  {
    hiragana: "た",
    katakana: "タ",
    romaji: "ta",
  },
  {
    hiragana: "ち",
    katakana: "チ",
    romaji: "chi",
  },
  {
    hiragana: "つ",
    katakana: "ツ",
    romaji: "tsu",
  },
  {
    hiragana: "て",
    katakana: "テ",
    romaji: "te",
  },
  {
    hiragana: "と",
    katakana: "ト",
    romaji: "to",
  },
  {
    hiragana: "な",
    katakana: "ナ",
    romaji: "na",
  },
  {
    hiragana: "に",
    katakana: "ニ",
    romaji: "ni",
  },
  {
    hiragana: "ぬ",
    katakana: "ヌ",
    romaji: "nu",
  },
  {
    hiragana: "ね",
    katakana: "ネ",
    romaji: "ne",
  },
  {
    hiragana: "の",
    katakana: "ノ",
    romaji: "no",
  },
  {
    hiragana: "は",
    katakana: "ハ",
    romaji: "ha",
  },
  {
    hiragana: "ひ",
    katakana: "ヒ",
    romaji: "hi",
  },
  {
    hiragana: "ふ",
    katakana: "フ",
    romaji: "fu",
  },
  {
    hiragana: "へ",
    katakana: "ヘ",
    romaji: "he",
  },
  {
    hiragana: "ほ",
    katakana: "ホ",
    romaji: "ho",
  },
  {
    hiragana: "ま",
    katakana: "マ",
    romaji: "ma",
  },
  {
    hiragana: "み",
    katakana: "ミ",
    romaji: "mi",
  },
  {
    hiragana: "む",
    katakana: "ム",
    romaji: "mu",
  },
  {
    hiragana: "め",
    katakana: "メ",
    romaji: "me",
  },
  {
    hiragana: "も",
    katakana: "モ",
    romaji: "mo",
  },
  {
    hiragana: "や",
    katakana: "ヤ",
    romaji: "ya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ゆ",
    katakana: "ユ",
    romaji: "yu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "よ",
    katakana: "ヨ",
    romaji: "yo",
  },
  {
    hiragana: "ら",
    katakana: "ラ",
    romaji: "ra",
  },
  {
    hiragana: "り",
    katakana: "リ",
    romaji: "ri",
  },
  {
    hiragana: "る",
    katakana: "ル",
    romaji: "ru",
  },
  {
    hiragana: "れ",
    katakana: "レ",
    romaji: "re",
  },
  {
    hiragana: "ろ",
    katakana: "ロ",
    romaji: "ro",
  },
  {
    hiragana: "わ",
    katakana: "ワ",
    romaji: "wa",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "を",
    katakana: "ヲ",
    romaji: "wo",
  },
  {
    hiragana: "ん",
    katakana: "ン",
    romaji: "n",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "が",
    katakana: "ガ",
    romaji: "ga",
  },
  {
    hiragana: "ぎ",
    katakana: "ギ",
    romaji: "gi",
  },
  {
    hiragana: "ぐ",
    katakana: "グ",
    romaji: "gu",
  },
  {
    hiragana: "げ",
    katakana: "ゲ",
    romaji: "ge",
  },
  {
    hiragana: "ご",
    katakana: "ゴ",
    romaji: "go",
  },
  {
    hiragana: "ざ",
    katakana: "ザ",
    romaji: "za",
  },
  {
    hiragana: "じ",
    katakana: "ジ",
    romaji: "ji",
  },
  {
    hiragana: "ず",
    katakana: "ズ",
    romaji: "zu",
  },
  {
    hiragana: "ぜ",
    katakana: "ゼ",
    romaji: "ze",
  },
  {
    hiragana: "ぞ",
    katakana: "ゾ",
    romaji: "zo",
  },
  {
    hiragana: "だ",
    katakana: "ダ",
    romaji: "da",
  },
  {
    hiragana: "ぢ",
    katakana: "ヂ",
    romaji: "ji",
  },
  {
    hiragana: "づ",
    katakana: "ヅ",
    romaji: "zu",
  },
  {
    hiragana: "で",
    katakana: "デ",
    romaji: "de",
  },
  {
    hiragana: "ど",
    katakana: "ド",
    romaji: "do",
  },
  {
    hiragana: "ば",
    katakana: "バ",
    romaji: "ba",
  },
  {
    hiragana: "び",
    katakana: "ビ",
    romaji: "bi",
  },
  {
    hiragana: "ぶ",
    katakana: "ブ",
    romaji: "bu",
  },
  {
    hiragana: "べ",
    katakana: "ベ",
    romaji: "be",
  },
  {
    hiragana: "ぼ",
    katakana: "ボ",
    romaji: "bo",
  },
  {
    hiragana: "ぱ",
    katakana: "パ",
    romaji: "pa",
  },
  {
    hiragana: "ぴ",
    katakana: "ピ",
    romaji: "pi",
  },
  {
    hiragana: "ぷ",
    katakana: "プ",
    romaji: "pu",
  },
  {
    hiragana: "ぺ",
    katakana: "ペ",
    romaji: "pe",
  },
  {
    hiragana: "ぽ",
    katakana: "ポ",
    romaji: "po",
  },
  {
    hiragana: "きゃ",
    katakana: "キャ",
    romaji: "kya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "きゅ",
    katakana: "キュ",
    romaji: "kyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "きょ",
    katakana: "キョ",
    romaji: "kyo",
  },
  {
    hiragana: "しゃ",
    katakana: "シャ",
    romaji: "sha",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "しゅ",
    katakana: "シュ",
    romaji: "shu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "しょ",
    katakana: "ショ",
    romaji: "sho",
  },
  {
    hiragana: "ちゃ",
    katakana: "チャ",
    romaji: "cha",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ちゅ",
    katakana: "チュ",
    romaji: "chu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ちょ",
    katakana: "チョ",
    romaji: "cho",
  },
  {
    hiragana: "にゃ",
    katakana: "ニャ",
    romaji: "nya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "にゅ",
    katakana: "ニュ",
    romaji: "nyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "にょ",
    katakana: "ニョ",
    romaji: "nyo",
  },
  {
    hiragana: "ひゃ",
    katakana: "ヒャ",
    romaji: "hya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ひゅ",
    katakana: "ヒュ",
    romaji: "hyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ひょ",
    katakana: "ヒョ",
    romaji: "hyo",
  },
  {
    hiragana: "みゃ",
    katakana: "ミャ",
    romaji: "mya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "みゅ",
    katakana: "ミュ",
    romaji: "myu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "みょ",
    katakana: "ミョ",
    romaji: "myo",
  },
  {
    hiragana: "りゃ",
    katakana: "リャ",
    romaji: "rya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "りゅ",
    katakana: "リュ",
    romaji: "ryu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "りょ",
    katakana: "リョ",
    romaji: "ryo",
  },
  {
    hiragana: "ぎゃ",
    katakana: "ギャ",
    romaji: "gya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ぎゅ",
    katakana: "ギュ",
    romaji: "gyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ぎょ",
    katakana: "ギョ",
    romaji: "gyo",
  },
  {
    hiragana: "じゃ",
    katakana: "ジャ",
    romaji: "ja",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "じゅ",
    katakana: "ジュ",
    romaji: "ju",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "じょ",
    katakana: "ジョ",
    romaji: "jo",
  },
  {
    hiragana: "びゃ",
    katakana: "ビャ",
    romaji: "bya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "びゅ",
    katakana: "ビュ",
    romaji: "byu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "びょ",
    katakana: "ビョ",
    romaji: "byo",
  },
  {
    hiragana: "ぴゃ",
    katakana: "ピャ",
    romaji: "pya",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ぴゅ",
    katakana: "ピュ",
    romaji: "pyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "ぴょ",
    katakana: "ピョ",
    romaji: "pyo",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "イェ",
    romaji: "ye",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ウィ",
    romaji: "wi",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ウェ",
    romaji: "we",
  },
  {
    hiragana: "",
    katakana: "ウォ",
    romaji: "wo",
  },
  {
    hiragana: "",
    katakana: "ヴァ",
    romaji: "va",
  },
  {
    hiragana: "",
    katakana: "ヴィ",
    romaji: "vi",
  },
  {
    hiragana: "",
    katakana: "ヴ",
    romaji: "vu",
  },
  {
    hiragana: "",
    katakana: "ヴェ",
    romaji: "ve",
  },
  {
    hiragana: "",
    katakana: "ヴォ",
    romaji: "vo",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ヴュ",
    romaji: "vyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "クァ",
    romaji: "kwa",
  },
  {
    hiragana: "",
    katakana: "クィ",
    romaji: "kwi",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "クェ",
    romaji: "kwe",
  },
  {
    hiragana: "",
    katakana: "クォ",
    romaji: "kwo",
  },
  {
    hiragana: "",
    katakana: "グァ",
    romaji: "gwa",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "シェ",
    romaji: "she",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ジェ",
    romaji: "je",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "チェ",
    romaji: "che",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ツァ",
    romaji: "tsa",
  },
  {
    hiragana: "",
    katakana: "ツィ",
    romaji: "tsi",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ツェ",
    romaji: "tse",
  },
  {
    hiragana: "",
    katakana: "ツォ",
    romaji: "tso",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ティ",
    romaji: "ti",
  },
  {
    hiragana: "",
    katakana: "トゥ",
    romaji: "tu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "テュ",
    romaji: "tyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ディ",
    romaji: "di",
  },
  {
    hiragana: "",
    katakana: "ドゥ",
    romaji: "du",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "デュ",
    romaji: "dyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "ファ",
    romaji: "fa",
  },
  {
    hiragana: "",
    katakana: "フィ",
    romaji: "fi",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "フェ",
    romaji: "fe",
  },
  {
    hiragana: "",
    katakana: "フォ",
    romaji: "fo",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "フュ",
    romaji: "fyu",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
  {
    hiragana: "",
    katakana: "",
    romaji: "",
  },
];
