import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "./services";
import { AuthContext } from "./AuthContext";
import { Loading } from "../shared/Loading";
import { Error } from "../shared/Error";
import { HttpStatus } from "../shared/constants";

const deleteConfirmation = "delete";

export function DeleteAccount({ close }: { close: () => void }): JSX.Element {
  const [errors, setErrors] = useState({
    http: "",
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [status, setStatus] = useState(HttpStatus.IDLE);
  const [confirmation, setConfirmation] = useState("");
  const noMatch =
    confirmation.toLowerCase() !== deleteConfirmation.toLowerCase();
  const activeErrors = Object.values(errors)[0];
  const { setAuthenticatedUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (noMatch || activeErrors) {
      setbuttonDisabled(true);
    } else {
      setbuttonDisabled(false);
    }
  }, [noMatch, activeErrors]);

  function updateConfirmation(event: ChangeEvent<HTMLInputElement>): void {
    setConfirmation(event.target.value);
  }

  async function handleDeleteAccount(): Promise<void> {
    setStatus(HttpStatus.WORKING);
    try {
      await deleteAccount();
      setAuthenticatedUser(false);
      setStatus(HttpStatus.SUCCESS);
      navigate("/welcome");
    } catch (error) {
      setErrors((errors) => ({ ...errors, http: (error as Error).message }));
      setStatus(HttpStatus.ERROR);
      console.error((error as Error).cause);
    }
  }

  function cancel(): void {
    close();
  }

  return (
    <div className="backdrop">
      <div className="dialog">
        <div className="title">
          <p>Delete account</p>
          <button className="icon" onClick={cancel}>
            <i className="material-icons">close</i>
          </button>
        </div>
        <div className="center stats-item">
          <p className="multiline">
            All your terms, stats, and account data will be deleted.
            <br />
            Please type "delete" to confirm.
          </p>
        </div>
        <input
          onChange={(event) => updateConfirmation(event)}
          required
          type="text"
          value={confirmation}
        />
        {status === HttpStatus.ERROR && (
          <div className="center">
            <Error message={errors.http} />
          </div>
        )}
        <div className="button-group">
          {status === HttpStatus.WORKING ? (
            <Loading />
          ) : (
            <button
              className="danger"
              disabled={buttonDisabled}
              onClick={handleDeleteAccount}
            >
              Delete account
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
