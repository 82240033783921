import { IndividualStats } from "./interfaces";

export function descending(): (
  a: IndividualStats,
  b: IndividualStats,
) => number {
  return (a, b): number => {
    if (b.score > a.score) {
      return 1;
    }
    if (b.score < a.score) {
      return -1;
    }
    if (b.score === a.score) {
      if (b.answered_count > a.answered_count) {
        return 1;
      }
      if (b.answered_count < a.answered_count) {
        return -1;
      }
    }
    return 0;
  };
}

export function titleCase(word: string): string {
  return word
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");
}
