import { NewTerm, Term } from "./interfaces";
import { Response } from "../shared/interfaces";
import { retryFetch, handleError, ExpectedError } from "../shared/utils";

export async function addTerm(term: NewTerm): Promise<void> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/add-term`, {
        body: JSON.stringify(term),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }),
    );
    const { status, message }: Response = await response?.json();
    if (status === "success") {
      return;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function getTerms(): Promise<Term[]> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/get-terms`, {
        credentials: "include",
        method: "GET",
      }),
    );
    const { status, data, message }: Response = await response?.json();
    if (status === "success") {
      return data as Term[];
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
    return data as Term[];
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function editTerm(term: Term): Promise<void> {
  const { id, created_at, edited_at, ...payload } = term;
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/edit-term/${id}`, {
        body: JSON.stringify(payload),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }),
    );
    const { status, message }: Response = await response?.json();
    if (status === "success") {
      return;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function deleteTerm(id: Term["id"]): Promise<void> {
  try {
    const response = await retryFetch(() =>
      fetch(
        `${process.env.REACT_APP_BACKEND_URL as string}/delete-term/${id}`,
        {
          credentials: "include",
          method: "POST",
        },
      ),
    );
    const { status, message }: Response = await response?.json();
    if (status === "success") {
      return;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
  } catch (error) {
    throw handleError(error as Error);
  }
}
