import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { App } from "./App";
import { AuthProvider } from "./auth/AuthContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    attachStacktrace: true,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    release: process.env.REACT_APP_VERSION,
    tracesSampleRate: 1.0,
  });
}

createRoot(document.getElementById("root") as HTMLElement).render(
  <AuthProvider>
    <StrictMode>
      <App />
    </StrictMode>
  </AuthProvider>,
);

serviceWorkerRegistration.register();
