import { Credentials, NewUser, UserInfo } from "./interfaces";
import { Response } from "../shared/interfaces";
import { retryFetch, handleError, ExpectedError } from "../shared/utils";

export async function registerUser(user: NewUser): Promise<void> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/register-user`, {
        body: JSON.stringify(user),
        headers: { "Content-Type": "application/json" },
        method: "POST",
      }),
    );
    const { status, message }: Response = await response?.json();
    if (status === "success") {
      return;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function logInUser(credentials: Credentials): Promise<void> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/log-in-user`, {
        body: JSON.stringify(credentials),
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        method: "POST",
      }),
    );
    const { status, message }: Response = await response?.json();
    if (status === "success") {
      return;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function getUserInfo(): Promise<UserInfo> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/get-user-info`, {
        credentials: "include",
        method: "GET",
      }),
    );
    const { status, data, message }: Response = await response?.json();
    if (status === "success") {
      return data as UserInfo;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
    return data as UserInfo;
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function logOutUser(): Promise<void> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/log-out-user`, {
        credentials: "include",
        method: "POST",
      }),
    );
    const { status, message }: Response = await response?.json();
    if (status === "success") {
      return;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
  } catch (error) {
    throw handleError(error as Error);
  }
}

export async function deleteAccount(): Promise<void> {
  try {
    const response = await retryFetch(() =>
      fetch(`${process.env.REACT_APP_BACKEND_URL as string}/delete-user`, {
        credentials: "include",
        method: "POST",
      }),
    );
    const { status, message }: Response = await response?.json();
    if (status === "success") {
      return;
    }
    if (status === "error") {
      throw new ExpectedError(message);
    }
    if (!response?.ok) {
      throw Error();
    }
  } catch (error) {
    throw handleError(error as Error);
  }
}
