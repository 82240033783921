import { StatsData } from "./interfaces";
import { descending, titleCase } from "./utils";
import { formatDecimal } from "../shared/utils";

export function Details({
  stats,
  group,
  close,
}: {
  stats: StatsData;
  group: string;
  close: () => void;
}): JSX.Element {
  const groupStats = stats.individual_stats.filter((i) => i.type === group)[0];

  return (
    <>
      <>
        <div className="tools">
          <div>
            <p className="details-group">{titleCase(groupStats.type)}</p>
            <p className="emphasis">{formatDecimal(groupStats.score)}</p>
            <p className="hint" style={{ marginTop: "6px" }}>
              {group === "vocabulary" ? `${stats.total_terms} total terms` : ""}
            </p>
          </div>
          <button className="icon flat" onClick={close}>
            <i className="material-icons">close</i>
          </button>
        </div>
      </>
      <table className="stats-individual-group">
        <thead>
          <tr>
            <td style={{ paddingBottom: "0px" }}></td>
            <td className="hint right low" style={{ paddingBottom: "0px" }}>
              Frequency
            </td>
            <td className="hint right low" style={{ paddingBottom: "0px" }}>
              Score
            </td>
          </tr>
        </thead>
        <tbody>
          {groupStats.stats.sort(descending()).map((item) => (
            <tr key={item.id}>
              <td className="stats-individual-item-text left">
                <p className="emphasis">{item.question} </p>
                <p className="hint"> ({item.solution})</p>
              </td>
              <td className="stats-individual-item-text right">
                <p>{item.answered_count} </p>
              </td>
              <td
                className="stats-individual-item-text right"
                style={{ width: "10%" }}
              >
                <p>{formatDecimal(item.score)}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
